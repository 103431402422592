<template>
  <div class="global-sort-container">
    <dropdown-wrap @onClickOutside="onClickOutside">
      <div class="sort-button-block">
        <b-button title="Sort By" @click="openSort" class="sort-button">
          <div class="inside-sort-button">
            <p v-if="!csatPage">
              {{ (showSortBy ? 'Sort By : ' : '') + getName() }}
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.356" height="14" viewBox="0 0 14.356 14" v-if="selected.orderBy == 'asc'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="a" d="M7.674 13.038v.482a.482.482 0 01-.482.482H.482A.482.482 0 010 13.52v-.482a.482.482 0 01.482-.482h6.711a.482.482 0 01.481.482zM6.288 11.002H.482A.482.482 0 010 10.521v-.482a.482.482 0 01.482-.482h5.806a.482.482 0 01.482.482v.482a.482.482 0 01-.482.481zM5.384 8.003H.482A.482.482 0 010 7.522V7.04a.482.482 0 01.482-.482h4.9a.482.482 0 01.482.482v.482a.482.482 0 01-.48.481zM4.48 4.67h-4A.482.482 0 010 4.189v-.482a.482.482 0 01.482-.482h4a.482.482 0 01.482.482v.482a.482.482 0 01-.484.481zM3.575 1.674H.482A.482.482 0 010 1.193V.711A.482.482 0 01.482.229h3.093a.482.482 0 01.482.482v.482a.482.482 0 01-.482.481zM14.215 3.841l-.341.337a.485.485 0 01-.681 0l-1.656-1.639v10.744a.479.479 0 01-.482.477h-.482a.479.479 0 01-.482-.477V2.539L8.435 4.178a.485.485 0 01-.681 0l-.341-.337a.473.473 0 010-.674l3.06-3.028a.485.485 0 01.681 0l3.06 3.028a.473.473 0 01.001.674z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.356" height="14" viewBox="0 0 14.356 14" v-if="selected.orderBy == 'desc'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="a" d="M7.674.964V.482A.482.482 0 007.192 0H.482A.482.482 0 000 .482v.482a.482.482 0 00.482.482h6.71a.482.482 0 00.482-.482zM6.288 2.999H.482A.482.482 0 000 3.481v.482a.482.482 0 00.482.482h5.806a.482.482 0 00.482-.482v-.482a.482.482 0 00-.482-.482zM5.384 5.998H.482A.482.482 0 000 6.48v.482a.482.482 0 00.482.482h4.9a.482.482 0 00.482-.482V6.48a.482.482 0 00-.48-.482zM4.479 9.33h-4a.482.482 0 00-.482.482v.482a.482.482 0 00.482.482h4a.482.482 0 00.482-.482v-.482a.482.482 0 00-.482-.482zM3.575 12.327H.482a.482.482 0 00-.482.482v.482a.482.482 0 00.482.482h3.093a.482.482 0 00.482-.482v-.482a.482.482 0 00-.482-.482zM14.215 10.159l-.34-.337a.485.485 0 00-.681 0l-1.656 1.639V.718a.479.479 0 00-.482-.477h-.482a.479.479 0 00-.482.477v10.743L8.436 9.822a.485.485 0 00-.681 0l-.341.337a.473.473 0 000 .674l3.059 3.028a.485.485 0 00.681 0l3.059-3.028a.473.473 0 00.002-.674z"/></svg>
          </div>
        </b-button>
        <div v-if="open" class="sort-dropdown">
          <!-- <p>{{ (showSortBy?'Sort By : ':'')+getName()}}</p> -->
          <p class="option-header" v-if="csatPage">Sort By :</p>
          <div class="sort-options">
            <div
              v-for="option of options"
              :key="option.name"
              @click="selectSort(option)"
              :class="[
                'default-sort',
                selected.type == option.alias ? 'active' : ''
              ]"
            >
              <p>{{ option.name }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="14.356" height="14" viewBox="0 0 14.356 14" v-bind:svg-inline="''" v-if="option.value == 'asc'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="a" d="M7.674 13.038v.482a.482.482 0 01-.482.482H.482A.482.482 0 010 13.52v-.482a.482.482 0 01.482-.482h6.711a.482.482 0 01.481.482zM6.288 11.002H.482A.482.482 0 010 10.521v-.482a.482.482 0 01.482-.482h5.806a.482.482 0 01.482.482v.482a.482.482 0 01-.482.481zM5.384 8.003H.482A.482.482 0 010 7.522V7.04a.482.482 0 01.482-.482h4.9a.482.482 0 01.482.482v.482a.482.482 0 01-.48.481zM4.48 4.67h-4A.482.482 0 010 4.189v-.482a.482.482 0 01.482-.482h4a.482.482 0 01.482.482v.482a.482.482 0 01-.484.481zM3.575 1.674H.482A.482.482 0 010 1.193V.711A.482.482 0 01.482.229h3.093a.482.482 0 01.482.482v.482a.482.482 0 01-.482.481zM14.215 3.841l-.341.337a.485.485 0 01-.681 0l-1.656-1.639v10.744a.479.479 0 01-.482.477h-.482a.479.479 0 01-.482-.477V2.539L8.435 4.178a.485.485 0 01-.681 0l-.341-.337a.473.473 0 010-.674l3.06-3.028a.485.485 0 01.681 0l3.06 3.028a.473.473 0 01.001.674z"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="14.356" height="14" viewBox="0 0 14.356 14" v-bind:svg-inline="''" v-if="option.value == 'desc'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="a" d="M7.674.964V.482A.482.482 0 007.192 0H.482A.482.482 0 000 .482v.482a.482.482 0 00.482.482h6.71a.482.482 0 00.482-.482zM6.288 2.999H.482A.482.482 0 000 3.481v.482a.482.482 0 00.482.482h5.806a.482.482 0 00.482-.482v-.482a.482.482 0 00-.482-.482zM5.384 5.998H.482A.482.482 0 000 6.48v.482a.482.482 0 00.482.482h4.9a.482.482 0 00.482-.482V6.48a.482.482 0 00-.48-.482zM4.479 9.33h-4a.482.482 0 00-.482.482v.482a.482.482 0 00.482.482h4a.482.482 0 00.482-.482v-.482a.482.482 0 00-.482-.482zM3.575 12.327H.482a.482.482 0 00-.482.482v.482a.482.482 0 00.482.482h3.093a.482.482 0 00.482-.482v-.482a.482.482 0 00-.482-.482zM14.215 10.159l-.34-.337a.485.485 0 00-.681 0l-1.656 1.639V.718a.479.479 0 00-.482-.477h-.482a.479.479 0 00-.482.477v10.743L8.436 9.822a.485.485 0 00-.681 0l-.341.337a.473.473 0 000 .674l3.059 3.028a.485.485 0 00.681 0l3.059-3.028a.473.473 0 00.002-.674z"/></svg>
            </div>
          </div>
        </div>
      </div>
    </dropdown-wrap>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { analyticsEvents } from '../../constant/data'
import DropdownWrap from '../Common/DropdownWrap.vue'
/**
 * This component is used to sort the data visible on the page. It's used in the **VocSummary**, **CSAT Drivers** and **Comments** pages.
 */
export default {
  name: '',
  components: {
    DropdownWrap
  },
  props: {
    /** The sort options available to this iteration of the component */
    options: {
      type: Array
    },
    /** The sort option that was selected by the user */
    selected: {
      type: Object
    },
    /** The eventHandler to be used when a sort option is selected */
    onChange: {
      type: Function
    },
    /** Displays *'Sort by: '* string in the rendered component */
    showSortBy: {
      type: Boolean,
      default: false
    },
    /** Indicates that this component needs to be rendered according to the style requirements of the csatPage */
    csatPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      sort: {}
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    /**
     * This event handler closes the sort menu dropdown
     * @public
     */
    onClickOutside(event) {
      this.open = false
    },
    /**
     * This event handler open the sort menu dropdown
     * @public
     */
    openSort() {
      this.open = !this.open
      if (this.open) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label: analyticsEvents.Sort,
          pageUrl: window.location.href
        })
      }
    },
    /**
     * This method returns the string value of the selected sort option
     * @public
     */
    getName() {
      // return this.options.filter((option) => option.alias === this.selected.type)[0].name
      return this.options.find((option) => option.alias === this.selected.type)
        .name
    },
    /**
     * This method creates the object indicating which option was selected
     * @public
     */
    selectSort(type) {
      let active
      if (type.value === null) {
        active = {
          type: type.alias,
          orderBy: null
        }
      } else {
        active = {
          type: type.alias,
          orderBy:
            this.selected.orderBy === 'desc' && type.alias === this.selected.type
              ? 'asc'
              : 'desc'
        }
      }
      /**
       * This event emits the object created by the *selectSor()* method
       */
      this.$emit('onChange', active)
    }
  }
}
</script>

<style lang="scss" scoped>
.global-sort-container {
  .sort-button-block {
    position: relative;
    display: flex;
    justify-content: end;
    .sort-button {
      border: var(--secondary-border-color);
      // background-color: #12151a;
      background: transparent;
      display: flex;
      justify-content: end;
      align-items: center;
      outline: none;
      border-radius: 4px;
      position: relative;
      height: 36px;
      padding: 10px;
      // width: 100%;
      svg {
        fill: #c2c7de;
        display: flex;
        // margin-left: 10px;
      }
      .inside-sort-button {
        display: flex;
        align-items: center;
        flex-direction: center;
        justify-content: space-between;
        // width: auto;
        // span {
        //   display: flex;
        //   flex-direction: row;
        //   width: 100%;
        // }
        p {
          color: var(--secondary-text-color);
          font-weight: 400;
          font-size: 14px;
          margin-right: 7px;
          // width: 75%;
        }
        img {
          width: 20%;
        }
      }
    }
    .sort-dropdown {
      position: absolute;
      background: var(--local-dropdown-background);
      box-shadow: 0px 6px 18px #00000033;
      border-radius: 4px;
      right: 0px;
      width: 170px;
      padding: 0px 0px 0px 0px;
      z-index: 25;
      top: 105%;
      overflow: hidden;
      .option-header {
        font-size: 13px;
        font-weight: 600;
        font-family: Quicksand;
        color: #a8aab7;
        padding: 10px 15px 5px 20px;
      }
      .sort-options {
        color: var(--description);
        // padding-left: 5px;
        .default-sort {
          padding: 6px 20px 6px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 14px;
            font-family: Quicksand;
            letter-spacing: 0px;
            cursor: pointer;
          }
          svg {
            fill: #c2c7de;
          }
          &:hover {
            background: var(--hover-local-dropdown-background);
          }
        }
        .active {
          background: var(--hover-local-dropdown-background);
        }
        .active svg {
          fill: var(--inava-primary);
        }
      }
    }
  }
}
</style>
